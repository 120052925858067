<template>
    <div class="tc-histroy" v-loading="loading">
        <el-collapse
            class="tc-histroy-collapse"
            v-model="activeNames"
            accordion
        >
            <el-collapse-item title="总控" name="btn-ctrl">
                <div class="global-btn-ctrl">
                    <el-button
                        icon="el-icon-refresh"
                        @click="doGetAllHistory"
                        size="mini"
                        >重新获取历史数据</el-button
                    >
                </div>
            </el-collapse-item>
        </el-collapse>
        <el-tabs tab-position="left" style="height: 200px;">
            <el-tab-pane label="历史开奖">
                <HistoryList :historys="historys" />
            </el-tab-pane>
            <el-tab-pane label="和距">
                <SumDistance :historys="historys" />
            </el-tab-pane>
            <el-tab-pane label="中奖概率">
                <Odds />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import HistoryList from "./historyList/index.vue";
import SumDistance from "./sumDistance/index.vue";
import Odds from "./Odds/index.vue";
import { getAllHistoryApi } from "@/api/tc/histroy";
export default {
    name: "TcHistroy",
    components: {
        HistoryList,
        SumDistance,
        Odds
    },
    computed: {},
    data() {
        return {
            loading: false,
            activeNames: "",
            historys: []
        };
    },
    methods: {
        async callGetAllHistoryApi() {
            return new Promise((resolve, reject) => {
                getAllHistoryApi()
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data);
                        } else {
                            reject(res.data.msg);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        doGetAllHistory() {
            this.loading = true;
            this.callGetAllHistoryApi()
                .then(data => {
                    this.$set(this, "historys", data);
                })
                .catch(err => {
                    this.$notify.error({
                        title: "获取失败",
                        message: err
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    created() {
        this.doGetAllHistory();
    }
};
</script>
<style lang="scss" scoped>
.tc-histroy {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .tc-histroy-collapse {
        margin-bottom: 20px;
    }
    .el-tabs {
        flex: 1;
        /deep/ .el-tabs__nav {
            user-select: none;
        }
        /deep/ .el-tabs__content,
        /deep/ .el-tabs__content .el-tab-pane {
            height: 100%;
        }
    }
}
</style>
