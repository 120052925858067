<template>
    <div class="history-list">
        <el-form
            ref="historyForm"
            :inline="true"
            :model="form"
            class="form-inline"
            label-width="80px"
            size="mini"
            label-position="left"
        >
            <el-row>
                <el-form-item>
                    <el-button type="primary" @click="queryTable"
                        >查询</el-button
                    >
                </el-form-item>
            </el-row>
        </el-form>
        <TableList
            class="history-table"
            ref="historyTable"
            :query="form"
            :columns="table.columms"
            :tableData.sync="table.tableData"
            :page.sync="table.page"
            :doHandler="getTablePage"
            fullHeight
            border
        >
            <template slot="ball" slot-scope="{ data }">
                <div class="column-scope-ball">
                    <div class="ball red-ball" v-for="index in 6" :key="index">
                        <span>{{ data["ball_0" + index] | doubleNumber }}</span>
                    </div>
                    <div class="ball  blue-ball">
                        <span>{{ data["ball_blue"] | doubleNumber }}</span>
                    </div>
                </div>
            </template>
        </TableList>
    </div>
</template>
<script>
import TableList from "@/components/table-list";
import columms from "./columns";

export default {
    name: "HistoryList",
    components: {
        TableList
    },
    props: {
        historys: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        historys: {
            handler() {
                this.queryTable();
            }
        }
    },
    computed: {},
    data() {
        return {
            form: {},
            table: {
                columms,
                tableData: [],
                page: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                }
            }
        };
    },
    methods: {
        queryTable() {
            this.$refs["historyTable"].doQuery();
        },
        getTablePage(query) {
            let { page, pageSize } = query;
            let start = (page - 1) * pageSize;
            let end = start + pageSize;
            let total = this.historys.length;
            if (end > total) {
                end = total;
            }
            this.$set(this.table, "tableData", this.historys.slice(start, end));
            this.$set(this.table.page, "page", page);
            this.$set(this.table.page, "pageSize", pageSize);
            this.$set(this.table.page, "total", total);
        }
    },
    filters: {
        doubleNumber(v) {
            return (Array(2).join(0) + v).slice(-2);
        }
    }
};
</script>
<style lang="scss" scoped>
.history-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    .history-table {
        flex: 1;
    }
    .ball {
        display: inline-block;
        color: #fff;
        font-weight: bold;
        padding: 5px;
        border-radius: 13px;
        font-size: 14px;
        line-height: 16px;
        & + .ball {
            margin-left: 5px;
        }
    }
    .red-ball {
        background-color: red;
    }
    .blue-ball {
        background-color: blue;
    }
}
</style>
