<template>
    <div class="odds-container">
        <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
                prop="H"
                label="">
            </el-table-column>
            <el-table-column
                prop="X1"
                label="一等奖">
            </el-table-column>
            <el-table-column
                prop="X2"
                label="二等奖">
            </el-table-column>
            <el-table-column
                prop="X3"
                label="三等奖">
            </el-table-column>
            <el-table-column
                prop="X4"
                label="四等奖">
            </el-table-column>
            <el-table-column
                prop="X5"
                label="五等奖">
            </el-table-column>
            <el-table-column
                prop="X6"
                label="六等奖">
            </el-table-column>
            <el-table-column
                prop="X0"
                label="无">
            </el-table-column>
        </el-table>
        <div>
            <p>综合中奖概率：<span>{{ sumP }}</span></p>
            <p>综合期望：<span>{{ sumE }}</span></p>
            <p>几何分布，期望中奖所需要的次数：{{ 1 / sumP }}</p>
            <p>几何分布，期望中奖所需要的次数方差：{{ (1 - sumP) / (sumP ** 2) }}</p>
        </div>
    </div>
</template>
<script>
import { combinations } from 'mathjs'
export default {
    name: "Odds",
    computed: {
        sumP() {
            let P = this.tableData[0]
            if (P) {
                let p = 0;
                Object.keys(P).forEach(key => {
                    if (typeof P[key] === 'number' && key !== 'X0') {
                        p += P[key]
                    }
                })
                return p;
            }
            return 0
        },
        sumE() {
            let E = this.tableData[1]
            if (E) {
                let e = 0;
                Object.keys(E).forEach(key => {
                    if (typeof E[key] === 'number') {
                        e += E[key]
                    }
                })
                return e;
            }
            return 0
        }
    },
    data() {
        return {
            tableData: []
        }
    },
    methods: {
        getP(r, b = 0) {
            let r_s = 6 - r;
            let b_s = 1 - b;
            return (combinations(6, r) * combinations(27, r_s) * combinations(1, b) * combinations(15, b_s)) / (combinations(33, 6) * combinations(16, 1))
        }
    },
    created() {

        let P1 = this.getP(6, 1);
        let P2 = this.getP(6, 0);
        let P3 = this.getP(5, 1);
        let P4 = this.getP(5, 0) + this.getP(4, 1);
        let P5 = this.getP(4, 0) + this.getP(3, 1);
        let P6 = this.getP(2, 1) + this.getP(1, 1) + this.getP(0, 1);
        let P0 = 1 - P1 - P2 - P3 - P4 - P5 - P6;
        let P = {
            H: 'P(X)',
            X1: P1,
            X2: P2,
            X3: P3,
            X4: P4,
            X5: P5,
            X6: P6,
            X0: P0,
        }
        this.tableData.push(P)

        let E = {
            H: 'E(X)',
            X1: P1 * 3999998,
            X2: P2 * 74998,
            X3: P3 * 2998,
            X4: P4 * 198,
            X5: P5 * 8,
            X6: P6 * 3,
            X0: P0 * -2
        }
        this.tableData.push(E)
    }
};
</script>
<style lang="scss" scoped></style>
