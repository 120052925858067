export default [
    {
        prop: "period",
        label: "期数",
        minWidth: 100
    },
    {
        prop: "lotterydraw_date",
        label: "开奖日期",
        minWidth: 100
    },
    {
        prop: "ball",
        label: "球号",
        minWidth: 240
        // },
        // {
        //     prop: "action",
        //     label: "操作",
        //     fixed: "right",
        //     width: 270
    }
];
